import React, { useEffect, useState } from 'react';

import AboutusIcon from '../../assets/svg-components/about_us.svg';
import CloseIcon from '../../assets/svg-components/close_icon.svg';
import { cn } from '../../utils/classname';
import Dialog from '../dialog/dialog';
import { Text } from '../text/text';
import { Title } from '../title/title';
import { Translate } from '../translate';
import './about-us-dialog.scss';

type ModalProps = {
  isOpen: boolean;
  setIsDialogOpen: any;
};

const AboutUsDialog: React.FC<ModalProps> = ({ isOpen, setIsDialogOpen }) => {
  const [isClosing, setIsClosing] = useState(false);

  useEffect(() => {
    if (isClosing) {
      setTimeout(() => {
        setIsDialogOpen(false);
        setIsClosing(false);
      }, 500);
    }
  }, [isClosing, setIsDialogOpen]);

  const wrapperClasses = cn('bd-about-us-dialog', isClosing ? 'closing' : '');

  return (
    <Dialog
      wrapperClass={wrapperClasses}
      isOpen={isOpen}
      setIsDialogOpen={setIsDialogOpen}
      isClosing={isClosing}
      setIsClosing={setIsClosing}
    >
      <div className="bd-about-us-dialog__back">
        <div className="bd-about-us-dialog__back__content">
          <div className="bd-about-us-dialog__back__left-column">
            <Title level="2" weight="bold">
              <Translate id="translate.about.us.dialog.title" />
            </Title>
            <div>
              <Text fontFamily="roboto">
                <Translate id="translate.about.us.dialog.text" />
              </Text>
            </div>
          </div>
          <div className="bd-about-us-dialog__back__right-column">
            <AboutusIcon className="bd-about-us-dialog__back__icon" />
          </div>
        </div>
      </div>
      <CloseIcon onClick={() => setIsClosing(true)} className="bd-about-us-dialog__close-icon" />
    </Dialog>
  );
};
export default AboutUsDialog;
