import React from 'react';

import { cn } from '../../utils/classname';
import { Text } from '../text/text';
import './checkbox.scss';

interface CheckboxProps extends React.ComponentPropsWithoutRef<'input'> {
  handleChange: (e) => void;
  checked: boolean;
  error?: boolean;
  children: React.ReactNode;
}

const Checkbox: React.FC<CheckboxProps> = ({ checked, error, handleChange, children, ...nativeProps }) => {
  const classes = cn('bd-checkbox__box', error && 'bd-checkbox__box-error', checked && 'checked');
  return (
    <div data-component="checkbox">
      <label className="bd-checkbox">
        <input
          className="bd-checkbox__input"
          type="checkbox"
          checked={checked}
          onChange={handleChange}
          {...nativeProps}
        />
        <span className={classes} />
        <Text size="small" color="default">
          {children}
        </Text>
      </label>
    </div>
  );
};

export default Checkbox;
