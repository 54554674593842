import React from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import useApi from '../../api/useApi';
import { Button } from '../../components/button/button';
import Container from '../../components/container/container';
import { SectionHero } from '../../components/section/section-hero';
import { SectionTitle } from '../../components/section/section-title';
import { Text } from '../../components/text/text';
import { Title } from '../../components/title/title';
import { Translate } from '../../components/translate';
import { PATHS } from '../../constants/routes';
import { HTTP_REQUEST_GET_EVENT } from '../../store/constants';
import { StoreContext } from '../../store/store';
import { parseJWT } from '../../utils/jwt';
import './raffle.scss';

const names = [
  'Jacob Martinez',
  'Emily Johnson',
  'Michael Smith',
  'Sophia Brown',
  'Daniel Garcia',
  'Olivia Martinez',
  'Ethan Jones',
  'Ava Lopez',
  'Matthew Davis',
  'Isabella Rodriguez',
  'Joshua Taylor',
  'Mia Thomas',
  'Andrew Wilson',
  'Chloe Moore',
  'Ryan Anderson',
  'Emma Jackson',
  'Nathan White',
  'Lily Harris',
  'Samuel Martin',
  'Grace Thompson',
  'Benjamin Lee',
  'Alyssa Hall',
  'Noah Young',
  'Ella King',
  'Logan Wright',
  'Amelia Scott',
  'James Green',
  'Madison Adams',
  'Alexander Baker',
  'Abigail Clark',
  'Aiden Evans',
  'Elizabeth Hill',
  'David Mitchell',
  'Charlotte Perez',
  'Anthony Ramirez',
  'Mia Sanchez',
  'William Phillips',
  'Victoria Torres',
  'Joseph Campbell',
  'Sarah Parker',
  'Christopher Edwards',
  'Hannah Collins',
  'Andrew Stewart',
  'Zoe Murphy',
  'Jack Lee',
  'Sophie Morris',
  'Lucas Cook',
  'Natalie Bailey',
  'Henry Diaz',
  'Gabriella Owens'
];

export const Raffle: React.FC = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('_token');
  const intervalRef = React.useRef(null);

  const { event } = useParams();
  const api = useApi();
  const navigate = useNavigate();

  const { dispatch } = React.useContext(StoreContext);

  const [currentEmail, setCurrentEmail] = React.useState('--');
  const [isRunning, setIsRunning] = React.useState(false);
  const [winner, setWinner] = React.useState('');
  const [disableButton, setDisableButton] = React.useState(false);

  React.useEffect(() => {
    api({ endpoint: `/getevent?slug=${event}` })
      .then(({ data }) => {
        dispatch({ type: HTTP_REQUEST_GET_EVENT, payload: data });
      })
      .catch(() => navigate(PATHS.home));
  }, [api, event, dispatch, navigate]);

  React.useEffect(() => {
    if (isRunning) {
      intervalRef.current = setInterval(() => {
        const randomIndex = Math.floor(Math.random() * names.length);
        setCurrentEmail(names[randomIndex]);
      }, 16);
    } else {
      clearInterval(intervalRef.current);
    }

    return () => clearInterval(intervalRef.current);
  }, [isRunning]);

  React.useEffect(() => {
    if (!parseJWT(token)) {
      navigate('/' + event);
    }
  }, [navigate, token, event]);

  const handleStart = () => {
    setIsRunning(true);
    setDisableButton(true);

    const startTime = Date.now(); // Capture the start time

    api({
      method: 'GET',
      endpoint: `/getwinner?token=${token}`,
      disableLoader: true
    })
      .then(({ data }) => {
        const { firstName, lastName } = data;
        setWinner([firstName, lastName].join(' '));
      })
      .catch((error) => setWinner('Session expired, please login through the dashboard again'))
      .finally(() => {
        // Calculate the remaining time to ensure a minimum 500ms has passed before enabling the button again
        const endTime = Date.now();
        const timeDiff = endTime - startTime;
        const delay = Math.max(0, 500 - timeDiff);
        setTimeout(() => {
          setDisableButton(false);
        }, delay);
      });
  };

  const handleStop = () => {
    setIsRunning(false);
    setCurrentEmail(winner);
  };

  return (
    <React.Fragment>
      <SectionHero className="sg-raffle-hero">
        <SectionTitle weight="bold" level="3">
          <Translate id={`translate.${event}.raffle.title`} />
        </SectionTitle>

        <Text align="center" size="large">
          <Translate id={`translate.${event}.raffle.subtitle`} />
        </Text>
      </SectionHero>

      <Container className="sg-raffle">
        <Title align="center" level="4">
          {currentEmail}
        </Title>

        <Button tag="button" color="primary" disabled={disableButton} onClick={isRunning ? handleStop : handleStart}>
          <Translate id={isRunning ? 'translate.raffle.stop' : 'translate.raffle.start'} />
        </Button>
      </Container>
    </React.Fragment>
  );
};
