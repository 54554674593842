export const getTranslations = async (lang) => {
  const language = lang || 'en';
  return await fetch(`https://spa-translations.siteground.com/siteground_events/${language}.json`)
    .then(async (response) => {
      return await response.json();
    })
    .catch((err) => {
      console.error(err);
    });
};
