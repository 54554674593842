import * as React from 'react';

import { cn } from '../../utils/classname';
import { WithTypographyProps } from '../with-typography/types';
import withTypographyClasses from '../with-typography/with-typography';
import './title.scss';

export interface TitleProps extends WithTypographyProps {
  level: '1' | '2' | '3' | '4' | '5' | '6';
  tag: 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
  color?: 'white';
  className: string;
  'data-component': string;
}

export const Title: React.FunctionComponent<Partial<TitleProps>> = (props) => {
  const { children, className, level, tag, color, ...propsToPropagate } = props;
  const classes = cn(
    'bd-title',
    `bd-title--level-${level}`,
    `bd-title--color-${color}`,
    withTypographyClasses(propsToPropagate),
    className
  );
  const tagToUse = tag ? tag : `h${level}`;

  return React.createElement(
    tagToUse,
    {
      className: classes,
      ...propsToPropagate
    },
    children
  );
};

Title.displayName = 'Title';

Title.defaultProps = {
  level: '1',
  weight: 'regular',
  align: 'left',
  wordBreak: 'word',
  'data-component': 'title'
};
