import * as React from 'react';

import { cn } from '../../utils/classname';
import { WithTypographyProps } from '../with-typography/types';
import withTypographyClasses from '../with-typography/with-typography';
import './text.scss';

interface TextProps extends WithTypographyProps {
  className: string;
  size: 'x-small' | 'small' | 'medium' | 'large';
  tag: 'span' | 'p';
  'data-component': string;
  color: 'default' | 'white';
  fontFamily: 'default' | 'roboto';
}

const Text: React.FunctionComponent<Partial<TextProps>> = (props) => {
  const { children, className, size, tag, color, fontFamily, ...propsToPropagate } = props;
  const classes = cn(
    `bd-text-${fontFamily}`,
    `bd-text--size-${size}`,
    `bd-text--color-${color}`,
    withTypographyClasses(propsToPropagate),
    className
  );

  return React.createElement(
    tag,
    {
      className: classes,
      ...propsToPropagate
    },
    children
  );
};

Text.displayName = 'Text';

Text.defaultProps = {
  color: 'default',
  fontFamily: 'default',
  size: 'medium',
  tag: 'p',
  weight: 'regular',
  align: 'left',
  wordBreak: 'word',
  'data-component': 'text'
};

export { Text };
