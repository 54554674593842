import { cn } from '../../utils/classname';
import { Translate } from '../translate';
import './textarea.scss';
import React from 'react';

interface TextareaProps extends React.HTMLAttributes<HTMLTextAreaElement> {
  error: string;
}

const Textarea: React.FC<TextareaProps> = ({ error, ...otherProps }) => {
  const classes = cn('bd-textarea', error && 'bd-textarea__error');

  return (
    <div className="bd-textarea__container">
      <textarea {...otherProps} className={classes} />
      {error && (
        <div className="bd-textarea__validation">
          <Translate id={error} />
        </div>
      )}
    </div>
  );
};

export default Textarea;
