import React, { PropsWithChildren } from 'react';

import DarkSparkle from '../../assets/svg-components/sparkle_dark.svg';
import WhiteSparkle from '../../assets/svg-components/sparkle_white.svg';
import { cn } from '../../utils/classname';
import './gradient.scss';

interface GradientProps extends PropsWithChildren<any> {
  colorTone?: 'cool' | 'warm';
  top?: string;
  bottom?: string;
  left?: string;
  right?: string;
}

type Sparkle = React.CSSProperties & {
  '--sparkle-scale': string;
};

const createSparkle = (): Sparkle => ({
  top: `${Math.random() * 100}%`,
  left: `${Math.random() * 100}%`,
  '--sparkle-scale': `${Math.random() * 0.7 + 0.3}`
});

export const Gradient: React.FC<GradientProps> = ({ colorTone, top, bottom, left, right, children }) => {
  const colorClasses = cn('bd-gradient__colors', `bd-gradient__colors__${colorTone}`);

  const [sparkles, setSparkles] = React.useState(() => Array.from({ length: 3 }, createSparkle));

  React.useEffect(() => {
    const interval = setInterval(() => {
      setSparkles(Array.from({ length: 3 }, createSparkle));
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="bd-gradient" style={{ top, bottom, left, right }}>
      <div className={colorClasses} />
      {sparkles.map((sparkle, index) => {
        const sparkleClasses = cn('bd-gradient__sparkle', `bd-gradient__sparkle__${index}`);

        return index % 2 === 0 ? (
          <WhiteSparkle key={index} className={sparkleClasses} style={sparkle} />
        ) : (
          <DarkSparkle key={index} className={sparkleClasses} style={sparkle} />
        );
      })}
      {children}
    </div>
  );
};

Gradient.defaultProps = {
  colorTone: 'cool'
};
