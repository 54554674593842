import React from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';

import useApi from '../../api/useApi';
import { Button } from '../../components/button/button';
import Checkbox from '../../components/checkbox/checkbox';
import { Dropdown } from '../../components/dropdown/dropdown';
import Input from '../../components/input/input';
import Loader from '../../components/loader/loader';
import { SectionHero } from '../../components/section/section-hero';
import { SectionTitle } from '../../components/section/section-title';
import { Text } from '../../components/text/text';
import Textarea from '../../components/textarea/textarea';
import ThankYouCard from '../../components/thank-you-card/thank-you-card';
import { Title } from '../../components/title/title';
import { Translate } from '../../components/translate';
import { PATHS } from '../../constants/routes';
import { HTTP_REQUEST_GET_EVENT } from '../../store/constants';
import { StoreContext } from '../../store/store';
import { DEFAULT_FORM_ERRORS, DEFAULT_FORM_VALUES } from './constants';
import './event.scss';
import { FormErrorsType, FormValuesType } from './types';
import { hasError, setFieldErrors } from './utils';

const options = [
  {
    value: 'Marketing Agency',
    label: 'translate.event.form.describe.option.1'
  },
  {
    value: 'Website development agency',
    label: 'translate.event.form.describe.option.2'
  },
  {
    value: 'Full-stack agency',
    label: 'translate.event.form.describe.option.3'
  },
  {
    value: 'Business owner',
    label: 'translate.event.form.describe.option.4'
  },
  {
    value: 'Freelancer (developer or designer)',
    label: 'translate.event.form.describe.option.5'
  },
  {
    value: 'WordPress enthusiast',
    label: 'translate.event.form.describe.option.6'
  },
  {
    value: 'SEO/Marketing consultant',
    label: 'translate.event.form.describe.option.7'
  },
  {
    value: 'Prefer not to share',
    label: 'translate.event.form.describe.option.8'
  },
  {
    value: 'Other',
    label: 'translate.event.form.describe.option.9'
  }
];

// eslint-disable-next-line sonarjs/cognitive-complexity
export const Event: React.FC = () => {
  const api = useApi();
  const navigate = useNavigate();
  const intl = useIntl();

  const { event } = useParams();
  const { state, dispatch } = React.useContext(StoreContext);

  const [isFetchingEvent, setIsFetchingEvent] = React.useState(true);
  const [formSubmitted, setFormSubmitted] = React.useState(false);
  const [showTextarea, setShowTextarea] = React.useState(false);
  const [formValues, setFormValues] = React.useState<FormValuesType>(DEFAULT_FORM_VALUES);
  const [formError, setFormError] = React.useState<FormErrorsType>(DEFAULT_FORM_ERRORS);

  const [customOccupation, setCustomOccupation] = React.useState('');

  const TermsOfServiceTranslation = state.event.privacyPolicyLink
    ? {
        id: 'translate.event.form.terms.and.privacy.policy',
        values: { tos: state.event.tosLink, privacy: state.event.privacyPolicyLink }
      }
    : { id: 'translate.event.form.terms', values: { tos: state.event.tosLink } };

  const handleFormChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (name === 'occupation' && value === 'Other') {
      setShowTextarea(true);
    }

    if (name === 'occupation' && value !== 'Other') {
      setShowTextarea(false);
      setCustomOccupation('');
    }

    setFormValues((prevState) => ({ ...prevState, [name]: type === 'checkbox' ? checked : value }));

    // clear errors during typing
    setFormError((prevErrors) => ({ ...prevErrors, [name]: '' }));
  };

  const handleSubmit = (e: React.MouseEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { firstName, lastName, email, occupation: occupation, terms, marketingTerms } = formValues;

    const errors = setFieldErrors({ firstName, lastName, email, occupation, customOccupation, terms });

    if (hasError(errors)) {
      setFormError(errors);
      return;
    }

    api({
      method: 'POST',
      endpoint: '/user',
      body: {
        firstName,
        lastName,
        email,
        what_describes_you: customOccupation || occupation,
        event: state.event.hash,
        tosAgreement: Number(terms),
        marketingAgreement: Number(marketingTerms)
      }
    }).then(() => setFormSubmitted(true));
  };

  React.useEffect(() => {
    api({ endpoint: `/getevent?slug=${event}` })
      .then(({ data }) => {
        dispatch({ type: HTTP_REQUEST_GET_EVENT, payload: data });
        setIsFetchingEvent(false);
      })
      .catch(() => navigate(PATHS.home));
  }, [api, event, dispatch, navigate]);

  const formCardTitle = formSubmitted ? 'translate.event.form.submitted' : 'translate.event.form.title';

  return (
    <Loader isLoadingAPIs={[isFetchingEvent]}>
      <SectionHero className="bd-event__hero">
        <SectionTitle weight="bold" level="3">
          <Translate id={`translate.${event}.qr-code.title`} />
        </SectionTitle>

        <Text align="center" size="large">
          <Translate id={`translate.${event}.qr-code.subtitle`} />
        </Text>
      </SectionHero>

      <ThankYouCard>
        <Title level="4" align="center" weight="bold">
          <Translate id={formCardTitle} />
        </Title>

        {!formSubmitted && (
          <form className="bd-form" onSubmit={handleSubmit}>
            <div className="bd-form__form_elements">
              <div className="bd-form__name-inputs">
                <Input
                  name="firstName"
                  placeholder="John"
                  value={formValues.firstName}
                  handleChange={handleFormChange}
                  label={
                    <Text color="default" size="small" weight="bold">
                      <Translate id="translate.event.form.first.name" />
                    </Text>
                  }
                  error={formError.firstName !== ''}
                />
                <Input
                  name="lastName"
                  placeholder="Smith"
                  value={formValues.lastName}
                  handleChange={handleFormChange}
                  label={
                    <Text color="default" size="small" weight="bold">
                      <Translate id="translate.event.form.last.name" />
                    </Text>
                  }
                  error={formError.lastName !== ''}
                />
              </div>
              <Input
                value={formValues.email}
                handleChange={handleFormChange}
                name="email"
                placeholder="youremail@domain.com"
                label={
                  <Text color="default" size="small" weight="bold">
                    <Translate id="translate.event.form.email" />
                  </Text>
                }
                error={formError.email !== ''}
              />

              <Dropdown
                name="occupation"
                options={options}
                optionLabel="label"
                optionValue="value"
                onChange={handleFormChange}
                label={
                  <Text color="default" size="small" weight="bold">
                    <Translate id="translate.event.form.describe.label" />
                  </Text>
                }
                error={formError.occupation !== ''}
              />
              {showTextarea && (
                <Textarea
                  placeholder={intl.formatMessage({ id: 'translate.event.form.textarea.label' })}
                  onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                    setCustomOccupation(event.target.value);
                    setFormError((prevErrors) => ({ ...prevErrors, customOccupation: '' }));
                  }}
                  error={formError.customOccupation}
                />
              )}
              <div className="bd-form__tos">
                <Checkbox
                  name="terms"
                  checked={Boolean(formValues.terms)}
                  error={Boolean(formError.terms)}
                  handleChange={handleFormChange}
                >
                  <Translate {...TermsOfServiceTranslation} />
                </Checkbox>

                <Checkbox
                  name="marketingTerms"
                  checked={Boolean(formValues.marketingTerms)}
                  handleChange={handleFormChange}
                >
                  <Translate id="translate.event.form.marketing.terms" />
                </Checkbox>
              </div>
            </div>

            <Button tag="button" color="green">
              <Text color="white" weight="bold">
                <Translate id="translate.event.form.submit" />
              </Text>
            </Button>
          </form>
        )}
      </ThankYouCard>
    </Loader>
  );
};
