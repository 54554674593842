type CONFIG = {
  LANGUAGE: string;
  BACKEND_API: string;
};

const getLang = () => {
  if (window.location.host.endsWith('events.siteground.com')) {
    return 'en';
  }

  if (window.location.host.endsWith('events.siteground.co.uk')) {
    return 'en';
  }

  if (window.location.host.endsWith('events.siteground.es')) {
    return 'es_ES';
  }

  return 'en';
};

const BASE_CONFIG = (): CONFIG => ({
  LANGUAGE: getLang(),
  BACKEND_API: 'https://admin.events.siteground.co.uk/api'
});

const PRODUCTION_CONFIG: CONFIG = {
  ...BASE_CONFIG(),
  BACKEND_API: `https://admin.${window.location.host}/api`
};

function getConfig(): CONFIG {
  switch (process.env.APP_ENV) {
    case 'production':
      return PRODUCTION_CONFIG;
    default:
      return BASE_CONFIG();
  }
}

export default getConfig();
