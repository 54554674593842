import React, { PropsWithChildren } from 'react';

import { cn } from '../../utils/classname';
import './container.scss';

export const Container: React.FC<PropsWithChildren<{ className?: string }>> = ({ children, className }) => {
  return <div className={cn('sg-container', className)}>{children}</div>;
};

export default Container;
