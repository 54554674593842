import React, { PropsWithChildren } from 'react';

import { cn } from '../../utils/classname';
import { WithTypographyProps } from '../with-typography/types';
import withTypographyClasses from '../with-typography/with-typography';
import './link.scss';

type NativeTextProps = JSX.IntrinsicElements['a'];

interface LinkProps extends PropsWithChildren<NativeTextProps>, WithTypographyProps {
  className: string;
  decoration: 'dotted-on-hover' | 'dotted';
  'data-component': string;
}

const Link: React.FunctionComponent<Partial<LinkProps>> = (props) => {
  const { children, className, decoration, ...propsToPropagate } = props;
  const classes = cn(
    'bd-link',
    `bd-link--decoration-${decoration}`,
    withTypographyClasses(propsToPropagate),
    className
  );

  return (
    <a className={classes} {...propsToPropagate}>
      {children}
    </a>
  );
};

Link.displayName = 'Link';

Link.defaultProps = {
  weight: 'regular',
  align: 'left',
  decoration: 'dotted',
  wordBreak: 'word',
  'data-component': 'link'
};

export { Link };
