import React from 'react';

import { SectionHero } from '../../components/section/section-hero';
import { SectionTitle } from '../../components/section/section-title';
import { Translate } from '../../components/translate';
import './home.scss';

export const Home: React.FC<{}> = () => {
  return (
    <React.Fragment>
      <SectionHero className="bd-event__hero">
        <SectionTitle weight="bold" level="3">
          <Translate id="translate.home.page.header" />
        </SectionTitle>
      </SectionHero>
    </React.Fragment>
  );
};
