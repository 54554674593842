import React from 'react';

import SGLogoDark from '../../assets/svg-components/logo_siteground_dark.svg';
import { Text } from '../text/text';
import { Translate } from '../translate';
import './footer.scss';

export const Footer: React.FC = () => {
  return (
    <div className="bd-footer-wrapper">
      <div className="bd-footer">
        <SGLogoDark className="bd-footer__siteground-logo" />
        <Text weight="bold" align="center">
          <Translate id="translate.footer.text" />
        </Text>
      </div>
    </div>
  );
};

export default Footer;
