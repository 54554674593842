import React from 'react';

import { cn } from '../../utils/classname';
import './field.scss';

type FieldProps = {
  children?: React.ReactNode;
  tag: string;
  className?: string;
  [key: string]: any;
};

export const Field: React.FC<FieldProps> = ({ children, tag, className, ...otherProps }) => {
  const classes = cn('bd-field', className);

  return React.createElement(
    tag,
    {
      className: classes,
      ...otherProps
    },
    children
  );
};

Field.defaultProps = {};
