import React from 'react';

import CONFIG from '../../../config';
import { getTranslations } from '../../api/fetchTranslations';
import { Layout } from '../../components/layout/layout';
import Loader from '../../components/loader/loader';
import TranslationsProvider from '../../components/translationsProvider';
import { Router } from '../../router';
import { HTTP_REQUEST_FINISHED, HTTP_REQUEST_STARTED } from '../../store/constants';
import { StoreContext } from '../../store/store';

const App: React.FC = () => {
  const [translations, setTranslations] = React.useState({});
  const { state, dispatch } = React.useContext(StoreContext);
  const lang = state.event.language || CONFIG.LANGUAGE;

  React.useEffect(() => {
    const translations = async () => {
      try {
        dispatch({ type: HTTP_REQUEST_STARTED });
        const data = await getTranslations(lang);
        setTranslations(data);
      } catch (error) {
        console.error('Something went wrong fetching translations');
      } finally {
        dispatch({ type: HTTP_REQUEST_FINISHED });
      }
    };

    translations();
  }, [lang, dispatch]);

  return (
    <Loader isLoadingAPIs={[state.isLoading]}>
      <TranslationsProvider keys={translations}>
        <Layout>
          <Router />
        </Layout>
      </TranslationsProvider>
    </Loader>
  );
};

export default App;
