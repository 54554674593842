import * as React from 'react';

import getDeviceInformation from '../../utils/device';
import { WithDeviceProps, WithDeviceState } from './types';

const withDevice = <ParentProps extends object>(Wrapped: React.ComponentType<ParentProps>) => {
  return class WithDevice extends React.Component<ParentProps & WithDeviceProps, WithDeviceState> {
    static defaultProps: Partial<WithDeviceProps> = {
      onInit: () => null,
      onResize: () => null
    };

    readonly state = {
      device: getDeviceInformation()
    };

    timeout = null;

    componentDidMount() {
      window.addEventListener('resize', this.onResize);

      this.props.onInit(this.state.device);
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.onResize);
    }

    onResize = () => {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }

      this.timeout = setTimeout(() => {
        const device = getDeviceInformation();
        this.setState({ device }, () => this.props.onResize(device));
      }, 300);
    };

    render() {
      const { onInit, onResize, children, ...props } = this.props as any;

      return (
        <Wrapped device={this.state.device} {...props}>
          {children}
        </Wrapped>
      );
    }
  };
};

export const useDevice = ({ onInit = (device) => null, onResize = (device) => null }) => {
  const [device, setDevice] = React.useState(getDeviceInformation());
  const timeout = React.useRef(null);

  const resizeListener = React.useCallback(() => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    timeout.current = setTimeout(() => {
      const device = getDeviceInformation();

      setDevice(device);
      onResize(device);
    }, 300);
  }, [onResize]);

  React.useEffect(() => {
    onInit(device);
    window.addEventListener('resize', resizeListener);

    return () => window.removeEventListener('resize', resizeListener);
  }, [resizeListener, onResize, onInit, device]);

  return device;
};

export default withDevice;
