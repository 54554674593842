import React from 'react';
import QRCode from 'react-qr-code';
import { useNavigate, useParams } from 'react-router-dom';

import useApi from '../../api/useApi';
import Container from '../../components/container/container';
import { SectionHero } from '../../components/section/section-hero';
import { SectionTitle } from '../../components/section/section-title';
import { Text } from '../../components/text/text';
import { Translate } from '../../components/translate';
import { PATHS, QR_CODE_PATH } from '../../constants/routes';
import { HTTP_REQUEST_GET_EVENT } from '../../store/constants';
import { StoreContext } from '../../store/store';
import './gr-code.scss';

export const QRCodePage = () => {
  const { event } = useParams();

  const { dispatch } = React.useContext(StoreContext);
  const navigate = useNavigate();
  const api = useApi();

  React.useEffect(() => {
    api({ endpoint: `/getevent?slug=${event}` })
      .then(({ data }) => {
        dispatch({ type: HTTP_REQUEST_GET_EVENT, payload: data });
      })
      .catch(() => navigate(PATHS.home));
  }, [api, event, dispatch, navigate]);

  return (
    <React.Fragment>
      <SectionHero className="sg-raffle-hero">
        <SectionTitle weight="bold" level="3">
          <Translate id={`translate.${event}.heading`} />
        </SectionTitle>

        <Text align="center" size="large">
          <Translate id={`translate.${event}.subheading`} />
        </Text>
      </SectionHero>

      <Container className="sg-qr-code">
        {/*// @ts-ignore*/}
        <QRCode value={location.href.replace(QR_CODE_PATH, '')} />
      </Container>
    </React.Fragment>
  );
};
