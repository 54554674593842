import React from 'react';

import { cn } from '../../utils/classname';
import './button.scss';

type ButtonProps = {
  href?: string;
  color?: 'primary' | 'secondary' | 'white' | 'green';
  size?: 'small' | 'medium' | 'large';
  tag?: 'a' | 'button';
  disabled?: boolean;
  onClick?: Function;
  className?: string;
  'data-component'?: string;
};

export const Button: React.FC<ButtonProps> = ({
  children,
  href,
  onClick,
  color,
  size,
  tag,
  disabled,
  className,
  ...propsToPropagate
}) => {
  const classes = cn('bd-btn', `bd-btn--color-${color}`, `bd-btn--${size}`, disabled && 'bd-btn--disabled', className);

  return React.createElement(
    tag,
    {
      className: classes,
      href,
      color,
      size,
      disabled,
      onClick,
      ...propsToPropagate
    },
    <span>{children}</span>
  );
};

Button.defaultProps = {
  color: 'primary',
  size: 'medium',
  tag: 'a',
  'data-component': 'button'
};
