import React, { useEffect, useRef } from 'react';

import { cn } from '../../utils/classname';
import DialogPortal from '../dialog/dialog-portal';
import './dialog.scss';

type ModalProps = {
  isOpen: boolean;
  wrapperClass: string;
  setIsDialogOpen: any;
  setIsClosing: any;
  isClosing: any;
};

const Dialog: React.FC<ModalProps> = ({ isOpen, wrapperClass, children, setIsDialogOpen, isClosing, setIsClosing }) => {
  const modalRef = useRef(null);

  const dialogClasses = cn('bd-dialog-container', isClosing ? 'closing' : '');

  useEffect(() => {
    if (isClosing) {
      setTimeout(() => {
        setIsDialogOpen(false);
        setIsClosing(false);
      }, 500);
    }
  }, [isClosing, setIsClosing, setIsDialogOpen]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setIsClosing(true);
      }
    };

    if (isOpen) {
      window.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen, setIsClosing]);

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setIsClosing(true);
    }
  };

  if (!isOpen) {
    return null;
  }

  return (
    <DialogPortal wrapperId="bd-dialog">
      <div className={dialogClasses} onClick={handleClickOutside}>
        <div ref={modalRef} className={wrapperClass}>
          {children}
        </div>
      </div>
    </DialogPortal>
  );
};

export default Dialog;
