import React from 'react';
import { useInView } from 'react-intersection-observer';

import { DEFAULT_OBSERVER_OPTIONS } from '../../constants/ovserver';
import { cn } from '../../utils/classname';
import './thank-you-card.scss';

const ThankYouCard = ({ children }) => {
  const { ref, inView } = useInView(DEFAULT_OBSERVER_OPTIONS);

  return (
    <div ref={ref} className={cn('bd-thankyou-card-wrapper', inView && 'bd-thankyou-card-wrapper--animated-default')}>
      <div className="bd-thankyou-card">
        <div className="bd-thankyou-card-content">{children}</div>
      </div>
    </div>
  );
};

export default ThankYouCard;
