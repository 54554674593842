import { cn } from '../../utils/classname';
import { WithTypographyProps } from './types';
import './with-typography.scss';

const withTypographyClasses = (props?: Partial<WithTypographyProps>) => {
  if (!props) {
    return null;
  }

  const { align, italic, transform, truncate, weight, wordBreak } = props;

  return cn(
    'bd-typography',
    truncate && 'bd-typography--truncate',
    italic && 'bd-typography--italic',
    `bd-typography--align-${align}`,
    `bd-typography--transform-${transform}`,
    `bd-typography--weight-${weight}`,
    `bd-typography--break-${wordBreak}`
  );
};

export default withTypographyClasses;
