import React from 'react';
import { createPortal } from 'react-dom';

import AnimatedLoader from '../../assets/images/20_years_loader.png';
import './loader.scss';

interface LoaderProps {
  children: React.ReactNode;
  isLoadingAPIs?: boolean[];
  hideChildren?: boolean;
}

export const Loader: React.FC<LoaderProps> = ({ children, hideChildren, isLoadingAPIs = [] }) => {
  const isLoading = Boolean(isLoadingAPIs.filter(Boolean).length);
  return (
    <React.Fragment>
      {hideChildren && isLoading ? null : children}
      {isLoading &&
        createPortal(
          <div className="bd-loader">
            <img src={AnimatedLoader} width="72" height="55" />
          </div>,
          document.querySelector('#root')
        )}
    </React.Fragment>
  );
};

export default Loader;
