import React from 'react';

import { cn } from '../../utils/classname';
import { Translate } from '../translate';
import './input.scss';

interface InputProps {
  label: React.ReactNode;
  placeholder: string;
  name: string;
  value: string;
  error: boolean;
  handleChange: (e) => void;
}

const Input: React.FC<InputProps> = ({ value, label, placeholder, name, error, handleChange }) => {
  const inputClasses = cn('bd-input-field', error && 'bd-input-field--error');
  return (
    <div className="bd-input-container" data-component="input">
      <label className="bd-input-label">{label}</label>
      <input
        className={inputClasses}
        type="text"
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
      />
      {error && (
        <div className="bd-input-field__validation">
          <Translate id={`translate.form.${name}.error`} />
        </div>
      )}
    </div>
  );
};

export default Input;
