import React from 'react';
import { IntlProvider } from 'react-intl';

export function TranslationsProvider({ children, keys }: any) {
  return (
    <IntlProvider locale="en" messages={keys}>
      {children}
    </IntlProvider>
  );
}

export default TranslationsProvider;
