import React from 'react';

import { cn } from '../../utils/classname';
import { Field } from '../field/field';
import { Translate } from '../translate';
import './dropdown.scss';

export const Dropdown = ({ options, optionValue, optionLabel, onChange, label, error, ...otherProps }) => {
  const fieldClasses = cn('bd-field--dropdown', 'bd-field--dropdown--size-ss', error && 'bd-field--dropdown__error');

  return (
    <div>
      <label className="bd-input-label">{label}</label>
      <Field tag="select" className={fieldClasses} onChange={onChange} {...otherProps}>
        <option value="" disabled selected hidden>
          <Translate id="translate.form.affiliation.dropdown.placeholder" />
        </option>
        {options.map((option, index) => (
          <option key={index} value={option[optionValue]}>
            <Translate id={option[optionLabel]} />
          </option>
        ))}
      </Field>
      {error && (
        <div className="bd-field--dropdown__validation">
          <Translate id="translate.form.affiliation.error" />
        </div>
      )}
    </div>
  );
};

Dropdown.defaultProps = {
  options: []
};
