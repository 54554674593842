import React from 'react';

import SGLogo from '../../assets/svg-components/logo_siteground_dark.svg';
import AboutUsDialog from '../about-us-dialog/about-us-dialog';
import { Button } from '../button/button';
import { Text } from '../text/text';
import { Translate } from '../translate';
import { useDevice } from '../with-device/with-device';
import './header.scss';

export const Header: React.FC = () => {
  const [isDialogOpen, setIsDialogOpen] = React.useState<boolean>(false);
  const device = useDevice({});

  const AboutUsButton = () => (
    <div className="bd-header__about-us" onClick={() => setIsDialogOpen(true)}>
      <Text weight="bold" size="large" className="bd-header__text">
        <Translate id="translate.header.about.us" />
      </Text>
    </div>
  );

  return (
    <div className="bd-header">
      {device.isPhone ? (
        <div className="bd-header__mobile">
          <SGLogo className="bd-header__siteground-logo" />
          <Button color="white" size="medium" className="bd-header__mobile__button">
            <AboutUsButton />
          </Button>
        </div>
      ) : (
        <div className="bd-header__desktop">
          <AboutUsButton />
          <div className="bd-header__siteground-logo">
            <SGLogo />
          </div>
          <div className="bd-header__spacer"></div>
        </div>
      )}

      <AboutUsDialog isOpen={isDialogOpen} setIsDialogOpen={setIsDialogOpen} />
    </div>
  );
};

export default Header;
