import { FormErrorsType, FormValuesType } from './types';

const MAX_CHARCTERS_LEN = 255;

export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const validateCustomOccupation = (occupation: string, customOccupation: string) => {
  switch (true) {
    case occupation === 'Other' && customOccupation === '':
      return 'translate.thankyou.validation.notice.custom_affiliation';
    case customOccupation.length >= MAX_CHARCTERS_LEN:
      return 'translate.thankyou.validation.notice.custom_affiliation.max.length';
    default:
      return '';
  }
};

export const setFieldErrors = ({
  firstName,
  lastName,
  email,
  occupation,
  customOccupation,
  terms
}: Omit<FormValuesType, 'marketingTerms'>): FormErrorsType => {
  return {
    firstName: firstName === '' ? 'translate.thankyou.validation.notice.firstname' : '',
    lastName: lastName === '' ? 'translate.thankyou.validation.notice.lastname' : '',
    email: !EMAIL_REGEX.test(email) ? 'translate.thankyou.validation.notice.email' : '',
    occupation: occupation === '' ? 'translate.thankyou.validation.notice.affiliation' : '',
    customOccupation: validateCustomOccupation(occupation, customOccupation),
    terms: !terms ? 'translate.thankyou.validation.notice.terms' : ''
  };
};

export const hasError = (errors: FormErrorsType): Boolean => Object.values(errors).some((x) => x !== '');
