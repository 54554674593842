import {
  CARD_ANIMATION_ENDED,
  HTTP_REQUEST_FINISHED,
  HTTP_REQUEST_GET_EVENT,
  HTTP_REQUEST_STARTED,
  SHOW_CARD_WITHOUT_ANIMATION,
  SHOW_CARD_WITH_ANIMATION
} from './constants';
import { ActionTypes, State } from './types';
import React, { Dispatch, createContext, useReducer } from 'react';

const initialState: State = {
  isLoading: true,
  activeCard: {
    id: null,
    isFlipped: false,
    triggerAnimation: false
  },
  event: {
    eventName: '',
    language: '',
    hash: '',
    tosLink: '',
    privacyPolicyLink: ''
  }
};

const StoreContext = createContext<{
  state: State;
  dispatch: Dispatch<ActionTypes>;
}>({
  state: initialState,
  dispatch: () => null
});

const StoreProvider = ({ children }) => {
  const defaultState = {
    ...initialState
  };

  const [state, dispatch] = useReducer((state: State, action: ActionTypes) => {
    switch (action.type) {
      case HTTP_REQUEST_STARTED:
        return { ...state, isLoading: true };
      case HTTP_REQUEST_FINISHED:
        return { ...state, isLoading: false };
      case HTTP_REQUEST_GET_EVENT:
        return { ...state, event: { ...action.payload } };

      case CARD_ANIMATION_ENDED:
        return { ...state, activeCard: { ...action.payload } };
      case SHOW_CARD_WITH_ANIMATION:
        return { ...state, activeCard: { triggerAnimation: true, isFlipped: false, ...action.payload } };
      case SHOW_CARD_WITHOUT_ANIMATION:
        return { ...state, activeCard: { triggerAnimation: false, isFlipped: true, ...action.payload } };

      default:
        return state;
    }
  }, defaultState);

  return <StoreContext.Provider value={{ state, dispatch }}>{children}</StoreContext.Provider>;
};

export { StoreContext, StoreProvider };
