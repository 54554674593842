import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { PATHS } from './constants/routes';
import { Event } from './pages/event/event';
import { Home } from './pages/home/home';
import { QRCodePage } from './pages/qr-code/gr-code';
import { Raffle } from './pages/raffle/raffle';

export const Router: React.FC = () => {
  return (
    <Routes>
      <Route path={PATHS.home} element={<Home />} />
      <Route path={PATHS.event} element={<Event />} />
      <Route path={PATHS.qr} element={<QRCodePage />} />
      <Route path={PATHS.raffle} element={<Raffle />} />
      <Route path="*" element={<Home />} />
    </Routes>
  );
};
