import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { Link } from './link/link';
import { Text } from './text/text';

interface CustomStyles {
  a?: React.CSSProperties;
  // extend with other tags if needed
}

export const formattedTags = (customStyles: CustomStyles) => ({
  href: (...href) => href,
  a: ([href, text]) => (
    <Link rel="noreferrer" href={href} target="_blank" weight="bold" style={customStyles.a}>
      {text}
    </Link>
  ),
  p: (text) => <p>{text}</p>,
  ul: (text) => <ul>{text}</ul>,
  li: (text) => <li>{text}</li>,
  em: (text) => <em>{text}</em>,
  strong: (text) => <strong>{text}</strong>,
  b: (text) => (
    <Text tag="span" weight="bold">
      {text}
    </Text>
  ),
  br: () => <br />
});
type Props = {
  id: string;
  tagName?: any;
  values?: object;
  customStyles?: CustomStyles;
};
export const Translate: React.FC<Props> = ({ id, tagName = null, values = null, customStyles = {} }) => {
  if (!id) {
    return null;
  }

  const tagsWithStyles = formattedTags(customStyles);

  return (
    <FormattedMessage
      tagName={tagName}
      id={id}
      defaultMessage={id}
      values={
        {
          ...tagsWithStyles,
          ...values
        } as any
      }
    />
  );
};
