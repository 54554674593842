import React from 'react';

import CONFIG from '../../config';
import { HTTP_REQUEST_FINISHED, HTTP_REQUEST_STARTED } from '../store/constants';
import { StoreContext } from '../store/store';

const API_HOST = CONFIG.BACKEND_API;

export const CustomError = function (object) {
  this.status = object.status;
  this.title = object.title;
  this.message = object.message;
  this.validation = object.validation || {};
};

interface RequestParams<T> {
  headers?: {
    'Content-Type': string;
    Accept: string;
  };
  method?: string;
  endpoint?: string;
  body?: T;
  disableLoader?: boolean;
}

const useApi = () => {
  const { dispatch } = React.useContext(StoreContext);

  return React.useCallback(
    async <T extends {}>({ endpoint, method, body, disableLoader = false }: RequestParams<T>) => {
      if (!disableLoader) {
        dispatch({ type: HTTP_REQUEST_STARTED });
      }

      const params: RequestParams<string> = {
        headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
        method
      };

      if (body) {
        params.body = JSON.stringify(body);
      }

      try {
        const response = await fetch(API_HOST + endpoint, params);

        const json = await response.json();

        switch (response.status) {
          case 200:
            return json;
          case 401:
          default:
            throw new CustomError(json);
        }
      } catch (error) {
        throw Error('An error occurred. Please try again later');
      } finally {
        if (!disableLoader) {
          dispatch({ type: HTTP_REQUEST_FINISHED });
        }
      }
    },
    [dispatch]
  );
};

export default useApi;
