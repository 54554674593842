import { FormErrorsType, FormValuesType } from './types';

export const DEFAULT_FORM_VALUES: FormValuesType = {
  firstName: '',
  lastName: '',
  email: '',
  occupation: '',
  customOccupation: '',
  terms: 0,
  marketingTerms: 0
};

export const DEFAULT_FORM_ERRORS: FormErrorsType = {
  firstName: '',
  lastName: '',
  email: '',
  occupation: '',
  customOccupation: '',
  terms: ''
};
