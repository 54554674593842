import { GetDeviceInformationType } from '../components/with-device/types';

const PHONE = 800;
const DESKTOP = 1500;
const userAgent = window.navigator.userAgent.toLowerCase();
const find = (needle) => userAgent.indexOf(needle) !== -1;

const isOpera = find('opera');

const isIosFirefox = find('fxios');
const isFirefox = find('firefox');

const isSafari = find('safari');

// Internet Explorer 6-11
// eslint-disable-next-line sonarjs/no-redundant-boolean
const isIE = /*@cc_on!@*/ false || !!(document as any).documentMode;

// Edge 20+
const isEdge = !isIE && !!(window as any).StyleMedia;

// if we need check for desktop only !!(window as any).chrome.webstore
const isIosChrome = find('crios');
const isChrome = find('chrome') || isIosChrome;
const isChromium = find('chromium');

const isBlink = (isChrome || isOpera) && !!(window as any).CSS;

const getBrowserName = () => {
  if (isEdge) {
    return 'edge';
  }

  if (isIosChrome) {
    return 'crios';
  }

  if (isChrome) {
    return 'chrome';
  }

  if (isChromium) {
    return 'chromium';
  }

  if (isOpera) {
    return 'opera';
  }

  if (isBlink) {
    return 'blink';
  }

  if (isIosFirefox) {
    return 'fxios';
  }

  if (isFirefox) {
    return 'firefox';
  }

  if (isSafari) {
    return 'safari';
  }

  return '';
};

const getBrowserVersion = () => {
  const regex = new RegExp(`${getBrowserName()}\/([0-9.]+)`);
  const browserVersionArray = userAgent.match(regex);

  if (isSafari && !isChrome && !isFirefox && !isIosFirefox) {
    const safariRegex = new RegExp('version/([0-9.]+)');
    const safariVersion = userAgent.match(safariRegex);
    return parseInt(safariVersion[1].split('.').join('').padEnd(4, '0'));
  }

  return browserVersionArray.length > 0 ? parseInt(browserVersionArray[1]) : 0;
};

const isSupported = () => {
  const browserVersion = getBrowserVersion();

  if (!browserVersion) {
    return false;
  }

  if (isEdge) {
    return browserVersion >= 15;
  }

  if (isChrome || isOpera || isBlink) {
    return browserVersion >= 58;
  }

  if (isFirefox) {
    return browserVersion >= 54;
  }

  if (isIosFirefox) {
    return browserVersion >= 16;
  }

  if (isSafari) {
    return browserVersion >= 1012;
  }

  return isFirefox || isSafari || isEdge || isChrome || isBlink;
};

const isWindows = () => find('windows');

const isIphone = () => !isWindows() && find('iphone');

const isIpod = () => find('ipod');

const isIpad = () => find('ipad');

const isMac = () => find('mac');

export const isIos = () => {
  return isIphone() || isIpod() || isIpad();
};

export const isApple = () => {
  return isIos() || isMac();
};

const isAndroid = () => !isWindows() && find('android');

const isAndroidPhone = () => isAndroid() && find('mobile');

const isAndroidTablet = () => isAndroid() && !find('mobile');

const isBlackberry = () => find('blackberry') || find('bb10') || find('rim');

const isBlackberryPhone = () => isBlackberry() && !find('tablet');

const isBlackberryTablet = () => isBlackberry() && find('tablet');

const isWindowsPhone = () => isWindows() && find('phone');

const isWindowsTablet = () => isWindows() && find('touch') && !isWindowsPhone();

const isFxos = () => (find('(mobile;') || find('(tablet;')) && find('; rv:');

const isFxosPhone = () => isFxos() && find('mobile');

const isFxosTablet = () => isFxos() && find('tablet');

const isMeego = () => find('meego');

// const isCordova = () => {
//   return window.cordova && location.protocol === 'file:';
// };

// const isNodeWebkit = () => {
//   return typeof window.process === 'object';
// };

const isUIPhone = () => window.innerWidth <= PHONE;

const isUITablet = () => window.innerWidth > PHONE && window.innerWidth <= DESKTOP;

const isUIDesktop = () => window.innerWidth > DESKTOP;

const isDevicePhone = () =>
  isAndroidPhone() || isIphone() || isIpod() || isWindowsPhone() || isBlackberryPhone() || isFxosPhone() || isMeego();

export const isPhone = () => isUIPhone() || isDevicePhone();

const isDeviceTablet = () =>
  isIpad() || isAndroidTablet() || isBlackberryTablet() || isWindowsTablet() || isFxosTablet();

const isTablet = () => isUITablet() || isDeviceTablet();

const isDesktop = () => isUIDesktop() || (!isTablet() && !isPhone());

const isTelevision = () => {
  const television = [
    'googletv',
    'viera',
    'smarttv',
    'internet.tv',
    'netcast',
    'nettv',
    'appletv',
    'boxee',
    'kylo',
    'roku',
    'dlnadoc',
    'roku',
    'pov_tv',
    'hbbtv',
    'ce-html'
  ];

  return !!television.find((tv) => find(tv));
};

const isPortrait = () => window.innerHeight / window.innerWidth > 1;

const isLandscape = () => window.innerHeight / window.innerWidth < 1;

const getWindowWidth = () => window.innerWidth;

const getWindowHeight = () => window.innerHeight;

export default function getDeviceInformation(): GetDeviceInformationType {
  return {
    isDesktop: isDesktop(),

    isPhone: isPhone(),
    isDevicePhone: isDevicePhone(),

    isTablet: isTablet(),
    isDeviceTablet: isDeviceTablet(),

    isTelevision: isTelevision(),

    isPortrait: isPortrait(),
    isLandscape: isLandscape(),

    browser: {
      name: getBrowserName(),
      version: getBrowserVersion(),
      isSupported: isSupported()
    },

    height: getWindowHeight(),
    width: getWindowWidth()
  };
}
