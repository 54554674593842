import React from 'react';

import { cn } from '../../utils/classname';
import './section-hero.scss';

type SectionHeroProps = {
  className?: string;
};

export const SectionHero: React.FC<SectionHeroProps> = ({ children, className }) => (
  <div className={cn('bd-section-hero', className)}>
    <div className="bd-section-hero__content">{children}</div>
  </div>
);
