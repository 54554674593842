import React from 'react';

import { Title, TitleProps } from '../title/title';
import './section-content.scss';

export const SectionTitle: React.FunctionComponent<Partial<TitleProps>> = ({
  children,
  className,
  level = '2',
  ...otherProps
}) => (
  <Title align="center" className={className} level={level} {...otherProps}>
    {children}
  </Title>
);
