import React from 'react';

import SquiggleLeft from '../../assets/svg-components/wavy_line_left.svg';
import SquiggleBottomRight from '../../assets/svg-components/wavy_line_right_bottom.svg';
import SquiggleTopRight from '../../assets/svg-components/wavy_line_right_top.svg';
import { StoreContext } from '../../store/store';
import Footer from '../footer/footer';
import { Gradient } from '../gradient/gradient';
import Header from '../header/header';
import Loader from '../loader/loader';
import './layout.scss';

export const Layout = ({ children }) => {
  const { state } = React.useContext(StoreContext);

  return (
    <Loader isLoadingAPIs={[state.isLoading]}>
      <div className="bd-layout">
        <div className="bd-layout__wrapper">
          <Gradient colorTone="cool" top="-150px" right="-100px">
            <SquiggleTopRight className="bd-layout__squiggle top-right" />
          </Gradient>
          <Gradient colorTone="warm" top="750px" left="-100px">
            <SquiggleLeft className="bd-layout__squiggle left" />
          </Gradient>
          <Gradient colorTone="cool" bottom="-300px" right="50px">
            <SquiggleBottomRight className="bd-layout__squiggle bottom-right" />
          </Gradient>

          <div className="bd-layout__content">
            <Header />
            {children}
            <Footer />
          </div>
        </div>
      </div>
    </Loader>
  );
};
