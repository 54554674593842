import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import './main.scss';
import App from './pages/app/app';
import { StoreProvider } from './store/store';

export const create = ({ root }) => {
  const appRoot = createRoot(root);

  appRoot.render(
    <BrowserRouter>
      <StoreProvider>
        <App />
      </StoreProvider>
    </BrowserRouter>
  );
};

create({ root: document.getElementById('root') });
