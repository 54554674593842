export const parseJWT = (token) => {
  if (!token) {
    console.warn('Invalid JWT: No token provided');

    return null;
  }

  // Split the JWT into parts
  const parts = token.split('.');

  if (parts.length === 3) {
    // The payload is the second part of the JWT
    const base64Url = parts[1];

    if (base64Url) {
      // Convert Base64Url to Base64
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');

      // Decode the Base64 string and parse the JSON
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join('')
      );

      return JSON.parse(jsonPayload);
    } else {
      console.warn('Invalid JWT: No payload found');
      return null;
    }
  } else {
    console.warn('Invalid JWT: The token should have 3 parts separated by dots');
    return null;
  }
};
